import React, { useState, useEffect, useMemo } from 'react';
import { useLogger } from '../hooks/useLogger';
import { useTranslation } from 'react-i18next';
import './css/ParallelTextHistory.css';
import './css/common.css';
// import WordDetailsModal from './WordDetailsModal';
import ProductPromotionModal from './ProductPromotionModal';
import LoadingProgress from './LoadingProgress';

const ParallelTextHistory = ({ 
  dyslexicFontEnabled, 
  textAlignment, 
  learningContent,
  nativeContent,
  associations,
  pagePronunciation,
  pageFurigana,
  learningLanguage, 
  nativeLanguage, 
  showNativeLanguage,
  showPhonetics,
  showFurigana,
  nativeLanguageDirection,
  learningLanguageDirection,
  userProfile,
  textSize,
  menuOpen,
}) => {
  const { t } = useTranslation();
  const logger = useLogger('ParallelTextHistory');
  const [hoverIndices, setHoverIndices] = useState({ sentenceIndex: null, wordIndices: { source: [], target: [] } });
  // const [isWordDetailsModalOpen, setIsWordDetailsModalOpen] = useState(false);
  const [isProductPromotionModalOpen, setIsProductPromotionModalOpen] = useState(false);
  const [selectedWordDetails, setSelectedWordDetails] = useState(null);
  const [toggledWords, setToggledWords] = useState(new Map());
  const [showPopup, setShowPopup] = useState(false);
  const [progress, setProgress] = useState(100);
  const POPUP_TIMEOUT = 8000; // Parameterized timeout in milliseconds
  const PROGRESS_INTERVAL = 100; // Update progress every 100ms

  const hasValidAssociations = useMemo(() => !!associations && associations.word_maps.length > 0, [associations]);
  const hasContent = useMemo(() => !!learningContent && Array.isArray(learningContent) && learningContent.length > 0, [learningContent]);

  const isPremiumUser = userProfile?.subscription_tier === 'premium';

  // logger.debug(`pageContent: ${JSON.stringify(pageContent)}`);
  // logger.debug(`textAlignment: ${textAlignment}`);

  useEffect(() => {
    logger.info(`learningLanguage: ${learningLanguage}`);
    logger.info(`nativeLanguage: ${nativeLanguage}`);
    logger.info(`showNativeLanguage: ${showNativeLanguage}`);
    logger.info(`showPhonetics: ${showPhonetics}`);
    logger.info('learningContent:', learningContent);
    logger.info('nativeContent:', nativeContent);
  }, [learningLanguage, nativeLanguage, showNativeLanguage, showPhonetics, learningContent, nativeContent, logger]);

  useEffect(() => {
    if (!showNativeLanguage) {
      setToggledWords(new Map());
      setShowPopup(true);
      setProgress(100);

      const progressInterval = setInterval(() => {
        setProgress(prev => Math.max(0, prev - (PROGRESS_INTERVAL / POPUP_TIMEOUT * 100)));
      }, PROGRESS_INTERVAL);

      const timer = setTimeout(() => {
        setShowPopup(false);
      }, POPUP_TIMEOUT);

      // Add cleanup for mobile navigation
      const handleMobileNavigation = () => {
        if (window.innerWidth <= 768 && menuOpen) {
          setShowPopup(false);
          clearTimeout(timer);
          clearInterval(progressInterval);
        }
      };

      handleMobileNavigation(); // Check initial state
      
      return () => {
        clearTimeout(timer);
        clearInterval(progressInterval);
      };
    }
  }, [showNativeLanguage, menuOpen]);

  // Add this effect to clear highlighting when content changes
  useEffect(() => {
    setHoverIndices({ sentenceIndex: null, wordIndices: { source: [], target: [] } });
    setToggledWords(new Map());
  }, [learningContent, nativeContent]);

  useEffect(() => {
    logger.info('ParallelTextHistory received nativeContent update:', nativeContent);
  }, [nativeContent]);

  useEffect(() => {
    logger.info('ParallelTextHistory received learningContent update:', learningContent);
  }, [learningContent]);

  const handleMouseEnter = (sentenceIndex, wordIndex, isLearning) => {
    if (!hasValidAssociations || !showNativeLanguage) return;

    const words = isLearning ? learningContent[sentenceIndex].words : nativeContent[sentenceIndex].words;
    if (isPunctuation(words[wordIndex])) return;

    const associationMap = associations.word_maps[sentenceIndex];
    const isSourceLearning = associations.source_language === learningLanguage;

    let sourceIndices = new Set();
    let targetIndices = new Set();

    if (isLearning === isSourceLearning) {
      // Clicked on source language word
      sourceIndices.add(wordIndex.toString());
      
      // First, find any direct target associations
      const directTargetIndices = associationMap.source_to_target[wordIndex.toString()] || [];
      
      // Even if we have -1, check if this source word appears in any target_to_source mappings
      Object.entries(associationMap.target_to_source).forEach(([targetIdx, sourceIndices_]) => {
        if (sourceIndices_.includes(Number(wordIndex))) {
          targetIndices.add(targetIdx);
          // Add all source words associated with this target
          sourceIndices_.forEach(srcIdx => {
            if (srcIdx !== -1) {
              sourceIndices.add(srcIdx.toString());
            }
          });
        }
      });

      // Also process any direct associations that aren't -1
      directTargetIndices.forEach(idx => {
        if (idx !== -1) {
          targetIndices.add(idx.toString());
          // Get all source words associated with this target word
          const relatedSourceIndices = associationMap.target_to_source[idx.toString()] || [];
          relatedSourceIndices.forEach(srcIdx => {
            if (srcIdx !== -1) {
              sourceIndices.add(srcIdx.toString());
            }
          });
        }
      });
    } else {
      // Clicked on target language word
      targetIndices.add(wordIndex.toString());
      const sourceIndexes = associationMap.target_to_source[wordIndex.toString()] || [];
      sourceIndexes.forEach(idx => {
        if (idx !== -1) {
          sourceIndices.add(idx.toString());
          // Get all target words associated with this source word
          const relatedTargetIndices = associationMap.source_to_target[idx.toString()] || [];
          relatedTargetIndices.forEach(tgtIdx => {
            if (tgtIdx !== -1) {
              targetIndices.add(tgtIdx.toString());
            }
          });
        }
      });
    }

    setHoverIndices({
      sentenceIndex: sentenceIndex,
      wordIndices: {
        source: Array.from(sourceIndices),
        target: Array.from(targetIndices)
      }
    });
  };

  const handleMouseLeave = () => {
    if (!hasValidAssociations || !showNativeLanguage) return;
    setHoverIndices({ sentenceIndex: null, wordIndices: { source: [], target: [] } });
  };

  const handleWordClick = (sentenceIndex, wordIndex, isLearning) => {
    // Check if user is premium
    if (userProfile?.subscription_tier !== 'premium') {
      setIsProductPromotionModalOpen(true);
      return;
    }

    const words = isLearning ? learningContent[sentenceIndex].words : nativeContent[sentenceIndex].words;
    if (isPunctuation(words[wordIndex])) return;

    if (!showNativeLanguage) {
      const key = `${sentenceIndex}-${wordIndex}`;
      setToggledWords(prev => {
        const next = new Map(prev);
        if (next.has(key)) {
          next.delete(key);
        } else {
          next.set(key, true);
        }
        return next;
      });
    }

    const isSourceLearning = associations?.source_language === learningLanguage;
    const sourceSentence = isSourceLearning ? learningContent[sentenceIndex] : nativeContent[sentenceIndex];
    const targetSentence = isSourceLearning ? nativeContent[sentenceIndex] : learningContent[sentenceIndex];
    
    const sourceWords = sourceSentence.words;
    const targetWords = targetSentence.words;
    
    let clickedWord, clickedWords, otherLanguageWords;

    if (hasValidAssociations) {
      const associationMap = associations.word_maps[sentenceIndex];
      const highlightedSourceIndices = new Set(hoverIndices.wordIndices.source);
      const highlightedTargetIndices = new Set(hoverIndices.wordIndices.target);

      if (isLearning === isSourceLearning) {
        clickedWord = sourceWords[wordIndex];
        clickedWords = sourceWords.filter((_, index) => highlightedSourceIndices.has(index.toString()));
        otherLanguageWords = targetWords.filter((_, index) => highlightedTargetIndices.has(index.toString()));
      } else {
        clickedWord = targetWords[wordIndex];
        clickedWords = targetWords.filter((_, index) => highlightedTargetIndices.has(index.toString()));
        otherLanguageWords = sourceWords.filter((_, index) => highlightedSourceIndices.has(index.toString()));
      }
    } else {
      clickedWord = isLearning ? sourceWords[wordIndex] : targetWords[wordIndex];
      clickedWords = [clickedWord];
      otherLanguageWords = [];
    }

    clickedWords = [...new Set(clickedWords)];
    otherLanguageWords = [...new Set(otherLanguageWords)];

    // setSelectedWordDetails({
    //   clickedWord,
    //   clickedWords,
    //   otherLanguageWords,
    //   clickedWordLanguageId: isLearning ? learningLanguage : nativeLanguage,
    //   otherLanguageId: isLearning ? nativeLanguage : learningLanguage,
    //   learningLanguage: learningLanguage,
    //   nativeLanguage: nativeLanguage
    // });
    // setIsWordDetailsModalOpen(true);
  };

  const isPunctuation = (word) => {
    return /^[.,!?;:。，！？；：'"`]$/.test(word);
  };

  const isValidPronunciation = (pronunciation) => {
    return pronunciation && !isPunctuation(pronunciation);
  };

  const SentenceSkeleton = () => {
    const shouldShowFurigana = learningLanguage === 'ja';
    
    return (
      <div className="sentence-skeleton">
        {[...Array(8)].map((_, i) => (
          <div key={i} className="word-wrapper">
            <div 
              className="word-skeleton" 
              style={{ width: `${Math.random() * 60 + 20}px` }} 
            />
            <div className="pronunciation-skeleton" />
            {shouldShowFurigana && <div className="furigana-skeleton" />}
          </div>
        ))}
      </div>
    );
  };

  const renderSentence = (sentence, isLearning, sentenceIndex) => {
    if (!sentence) return <SentenceSkeleton />;
    
    const { words } = sentence;
    if (!words || !Array.isArray(words)) {
      logger.error('Invalid words structure:', words);
      return <SentenceSkeleton />;
    }

    const isSourceLearning = associations?.source_language === learningLanguage;
    const hasPronunciation = showPhonetics && isLearning && pagePronunciation && pagePronunciation[sentenceIndex];
    const hasFurigana = showFurigana && isLearning && pageFurigana && pageFurigana[sentenceIndex];
    const shouldShowFurigana = isLearning && learningLanguage === 'ja';
    const direction = isLearning ? learningLanguageDirection : nativeLanguageDirection;

    return (
      <div className={`sentence ${hasPronunciation ? 'has-pronunciation' : ''} ${hasFurigana ? 'has-furigana' : ''}`} dir={direction}>
        {words.map((word, wordIndex) => {
          // Handle whitespace with specific class
          if (/^\s+$/.test(word)) {
            return <span key={`s${wordIndex}`} className="punctuation whitespace">{word}</span>;
          }

          const isHighlighted = hasValidAssociations &&
            hoverIndices.sentenceIndex === sentenceIndex && 
            hoverIndices.wordIndices[isLearning === isSourceLearning ? 'source' : 'target'].includes(wordIndex.toString());

          const pronunciation = hasPronunciation && pagePronunciation[sentenceIndex][wordIndex];
          const furigana = hasFurigana && pageFurigana[sentenceIndex][wordIndex];

          const key = `${sentenceIndex}-${wordIndex}`;
          const isToggled = !showNativeLanguage && toggledWords.has(key);
          
          let displayWord = word.replace(/\\'/g, "'");
          // Only try to show translations if associations are available
          if (isToggled && isLearning && hasValidAssociations && associations) {
            const associationMap = associations.word_maps[sentenceIndex];
            const isSourceLearning = associations.source_language === learningLanguage;
            const targetIndices = isSourceLearning ? 
              associationMap.source_to_target[wordIndex.toString()] || [] :
              associationMap.target_to_source[wordIndex.toString()] || [];
            
            if (targetIndices.length > 0 && nativeContent && nativeContent[sentenceIndex]) {
              // Get the translation words and join them with spaces
              displayWord = targetIndices
                .filter(index => index !== -1)
                .map(index => {
                  const word = nativeContent[sentenceIndex].words[index];
                  return word ? word.replace(/\\'/g, "'") : '';
                })
                .filter(word => word !== '') // Remove any empty strings
                .join(' '); // Add space between words
              
              if (displayWord === '') {
                displayWord = '*';
              }
            }
          }

          return (
            <span
              key={wordIndex}
              className={`word-wrapper ${isHighlighted ? 'highlight-wrapper' : ''} ${isToggled ? 'toggled' : ''}`}
              onMouseEnter={() => !isPunctuation(word) && associations && handleMouseEnter(sentenceIndex, wordIndex, isLearning)}
              onMouseLeave={() => !isPunctuation(word) && associations && handleMouseLeave()}
              onClick={() => !isPunctuation(word) && handleWordClick(sentenceIndex, wordIndex, isLearning)}
            >
              <ruby className="furigana-wrapper">
                {displayWord}
                {shouldShowFurigana && showFurigana && !isPunctuation(word) && (
                  <rt className="furigana-text">
                    {hasFurigana ? furigana : <div className="furigana-skeleton" />}
                  </rt>
                )}
              </ruby>
              {showPhonetics && isLearning && !isPunctuation(word) && pronunciation && isValidPronunciation(pronunciation) && (
                hasPronunciation ? (
                  <span className='pronunciation'>{pronunciation}</span>
                ) : (
                  <div className='pronunciation-skeleton' />
                )
              )}
            </span>
          );
        })}
      </div>
    );
  };

  // First check if we have any content at all
  const hasAnyContent = learningContent || nativeContent;

  const contentLength = Math.max(
    learningContent?.length || 0,
    nativeContent?.length || 0
  );

  // If we have at least one content array, start rendering
  return (
    <div 
      className={`parallel-text-history ${textAlignment} ${dyslexicFontEnabled ? 'dyslexic-font' : ''}`} 
      style={{ 
        position: 'relative',
        fontSize: `${textSize}%`,
        lineHeight: textSize > 100 ? '1.4' : '1.6'
      }}
    >
      {isPremiumUser ? (
        // Premium user view - show both languages
        <>
          {[...Array(contentLength)].map((_, index) => (
            <div key={index} className={`sentence-pair ${dyslexicFontEnabled ? 'dyslexic-font' : ''}`}>
              <div className="language-one" dir={learningLanguageDirection}>
                {learningContent ? 
                  renderSentence(learningContent[index], true, index) : 
                  <SentenceSkeleton />
                }
              </div>
              {showNativeLanguage && (
                <div className="language-two" dir={nativeLanguageDirection}>
                  {nativeContent ? 
                    renderSentence(nativeContent[index], false, index) : 
                    <SentenceSkeleton />
                  }
                </div>
              )}
            </div>
          ))}
        </>
      ) : (
        // Non-premium user view - only show native language
        <>
          {nativeContent?.map((sentence, sentenceIndex) => (
            <div key={sentenceIndex} className={`sentence-pair ${dyslexicFontEnabled ? 'dyslexic-font' : ''}`}>
              <div className="language-two" dir={nativeLanguageDirection}>
                {renderSentence(sentence, false, sentenceIndex)}
              </div>
            </div>
          ))}
        </>
      )}
      <ProductPromotionModal
        isOpen={isProductPromotionModalOpen}
        onClose={() => setIsProductPromotionModalOpen(false)}
        nativeLanguageDirection={nativeLanguageDirection}
      />
      {showPopup && (
        <div className="notification-popup" dir={nativeLanguageDirection}>
          <span style={{ whiteSpace: 'pre-line' }}>
            {t('parallelTextHistory.nativeLanguageEnabled')}
          </span>
          <button 
            className="popup-close-button" 
            onClick={() => setShowPopup(false)}
            aria-label={t('common.close')}
          >
            ×
          </button>
          <div 
            className="popup-timer-bar" 
            style={{ width: `${progress}%` }} 
          />
        </div>
      )}
    </div>
  );
};

export default ParallelTextHistory;