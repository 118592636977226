import React from 'react';
import { useTranslation } from 'react-i18next';
import { X, Copy, Meta, Facebook, Link2, Send } from 'lucide-react';
import './css/ShareModal.css';

const ShareModal = ({ isOpen, onClose, url, title }) => {
  const { t } = useTranslation();
  const [showTooltip, setShowTooltip] = React.useState(false);

  if (!isOpen) return null;

  const templateMessage = t('share.template', { all_books_all_languages: 'All Books, All Languages' });
  
  const shareLinks = {
    x: `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(templateMessage + '\n\n')}`,
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(templateMessage + '\n\n')}`,
    linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}&summary=${encodeURIComponent(templateMessage + '\n\n')}`,
    whatsapp: `https://wa.me/?text=${encodeURIComponent(`${templateMessage}\n\n${url}`)}`,
  };

  const handleCopyLink = async () => {
    try {
      // Try the modern clipboard API first
      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(url);
      } else {
        // Fallback for mobile: Create temporary textarea element
        const textarea = document.createElement('textarea');
        textarea.value = url;
        textarea.style.position = 'fixed'; // Avoid scrolling to bottom
        textarea.style.opacity = '0';
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
      }
      setShowTooltip(true);
      setTimeout(() => setShowTooltip(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>
          <X size={20} />
        </button>
        <h2>{t('share.title')}</h2>
        <div className="share-buttons">
          <button onClick={() => window.open(shareLinks.x, '_blank')} className="share-button twitter">
            <X size={20} color="white" />
            <span style={{ color: 'white' }}>Twitter</span>
          </button>
          <button onClick={() => window.open(shareLinks.facebook, '_blank')} className="share-button facebook">
            <Facebook size={20} />
            Meta
          </button>
          <button onClick={() => window.open(shareLinks.linkedin, '_blank')} className="share-button linkedin">
            <Link2 size={20} />
            LinkedIn
          </button>
          <button onClick={() => window.open(shareLinks.whatsapp, '_blank')} className="share-button whatsapp">
            <Send size={20} />
            WhatsApp
          </button>
          <button onClick={handleCopyLink} className="share-button copy">
            <Copy size={20} />
            <span className="button-content">
              {t('share.copyLink')}
              {showTooltip && <span className="tooltip"><span className="divider"> | </span>{t('share.copied')}</span>}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;