const getGoogleMeasurementId = () => {
    return process.env.REACT_APP_GOOGLE_MEASUREMENT_ID || '';
}

export const GOOGLE_MEASUREMENT_ID = getGoogleMeasurementId();

const getPricingURL = () => {
    const subdomain = process.env.REACT_APP_MARKETING_SUBDOMAIN || '';
    const protocol = subdomain.includes('localhost') ? 'http' : 'https';
    return `${protocol}://${subdomain}/pricing`;
}

export const PRICING_URL = getPricingURL();
