import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { apiRequest, setUserLocaleHeader, isAdminMode as checkAdminMode } from '../config/api.js';
import { useLogger } from '../hooks/useLogger';
import { Heart, Settings as SettingsIcon, User, ChevronLeft, ChevronRight, Book, Bookmark } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import './css/ParallelTextViewer.css';
import './css/Menu.css';
import './css/common.css';
import ProductPromotionModal from './ProductPromotionModal';
import Settings from './Settings';
import Profile from './Profile';
import { loadStripe } from '@stripe/stripe-js';
import Modal from './Modal';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Menu = ({ 
  onBookSelect, 
  onLanguageSelect, 
  initialNativeLanguage,
  initialLearningLanguage, 
  open, 
  currentTheme, 
  onThemeChange, 
  textAlignment, 
  onTextAlignmentChange, 
  dyslexicFontEnabled,
  onDyslexicFontToggle, 
  onOpenCookiePreferences, 
  userProfile,
  onLogin,
  onSignup,
  onLogout,
  onPasswordReset,
  onLanguageOptionsChange,
  shouldTriggerSubscribe,
  onSubscribeTriggered,
  setUserProfile,
  onBookmarkClick,
  bookmarks,
  selectedBookId: initialSelectedBookId,
  onRefetchProfile,
}) => {
  const { t, i18n } = useTranslation();
  const [books, setBooks] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [difficultyLevel, setDifficultyLevel] = useState('');
  const [sortBy, setSortBy] = useState('difficulty_level');
  const [nativeLanguage, setNativeLanguage] = useState(initialNativeLanguage || '');
  const [learningLanguage, setLearningLanguage] = useState(initialLearningLanguage || '');
  const [search, setSearch] = useState('');
  const [languageOptions, setLanguageOptions] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [favoriteBooks, setFavoriteBooks] = useState([]);
  const [userSubscription, setUserSubscription] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const [sidebarWidth, setSidebarWidth] = useState(350);
  const sidebarRef = useRef(null);
  const [isResizing, setIsResizing] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [signupErrorMessage, setSignupErrorMessage] = useState(false);
  const [textDirection, setTextDirection] = useState('ltr');

  const logger = useLogger('Menu');
  
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [selectedBookId, setSelectedBookId] = useState(initialSelectedBookId || null);
  const location = useLocation();
  const navigate = useNavigate();

  const [currentBook, setCurrentBook] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(false);

  // Add state for product promotion modal
  const [isProductPromotionModalOpen, setIsProductPromotionModalOpen] = useState(false);

  // Add loading state for favorites
  const [isFavoritesLoading, setIsFavoritesLoading] = useState({});

  const [showBookmarkedOnly, setShowBookmarkedOnly] = useState(false);

  // Add new state for category
  const [category, setCategory] = useState('');

  const [categories, setCategories] = useState([]);
  const [isCategoriesLoading, setIsCategoriesLoading] = useState(false);

  // Add debounce timer ref
  const debounceTimerRef = useRef(null);

  // Add new state for admin mode and deactivated books filter
  const [showDeactivatedBooks, setShowDeactivatedBooks] = useState(false);
  const [isAdminMode, setIsAdminMode] = useState(false);

  // Add new function to fetch categories
  const fetchCategories = useCallback(async () => {
    // Skip if already loading or if we already have categories or if we've received an empty array
    if (isCategoriesLoading || categories.length > 0 || categories.emptyResponseReceived) return;
    
    setIsCategoriesLoading(true);
    try {
      const response = await apiRequest('GET', 'CATEGORIES');
      setCategories(prev => {
        // If response is empty array, mark it in the state object
        if (Array.isArray(response) && response.length === 0) {
          return Object.assign([], { emptyResponseReceived: true });
        }
        return response;
      });
    } catch (error) {
      logger.error('Error fetching categories:', error);
    } finally {
      setIsCategoriesLoading(false);
    }
  }, [logger, categories, isCategoriesLoading]);

  // Add useEffect to load categories on mount
  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  // Update the getCategoryTranslation function
  const getCategoryTranslation = useCallback((categoryKey) => {
    try {
      // Split the composite key (e.g., "beginner.daily_routines")
      const [level, topic] = categoryKey.split('.');
      return t(`story_categories.topics.${level}.${topic}`);
    } catch (error) {
      logger.error('Error translating category:', error);
      return categoryKey; // Fallback to raw key if translation fails
    }
  }, [t]);

  // Log only when languages change
  useEffect(() => {
    logger.info('Native language:', nativeLanguage);
    logger.info('Learning language:', learningLanguage);
  }, [nativeLanguage, learningLanguage]);

  // Memoize language options
  const sortedLearningLanguages = useMemo(() => {
    return Object.entries(languageOptions)
      .filter(([id]) => id !== nativeLanguage)
      .sort(([aId, aData], [bId, bData]) => 
        t(`languages.${aId}`).localeCompare(t(`languages.${bId}`), i18n.language)
      )
      .map(([id, data]) => ({ id, ...data }));
  }, [languageOptions, nativeLanguage, t, i18n.language]);

  const resetBookSelection = useCallback(() => {
    logger.info('XXXXXXXXXXXX Resetting book selection');
    setCurrentBook(null);
    setSelectedBookId(null);
  }, []);

  // Use useCallback for event handlers
  const handleNativeLanguageChange = useCallback((e) => {
    const newNativeLanguage = e.target.value;
    setNativeLanguage(newNativeLanguage);
    if (newNativeLanguage === learningLanguage) {
      const newLearningLanguage = Object.keys(languageOptions).find(id => id !== newNativeLanguage) || '';
      setLearningLanguage(newLearningLanguage);
    }
    i18n.changeLanguage(newNativeLanguage);
    setUserLocaleHeader(newNativeLanguage);
    resetBookSelection();
  }, [learningLanguage, languageOptions, i18n, setUserLocaleHeader]);

  const handleLearningLanguageInteraction = useCallback((e) => {
    // Show promotion modal for non-premium users
    if (!userProfile || userProfile.subscription_tier !== 'premium') {
      e.preventDefault();
      e.stopPropagation();
      setIsProductPromotionModalOpen(true);
    }
  }, [userProfile]);

  const handleLearningLanguageChange = useCallback((e) => {
    const newLearningLanguage = e.target.value;
    setLearningLanguage(newLearningLanguage);
    if (newLearningLanguage === nativeLanguage) {
      const newNativeLanguage = Object.keys(languageOptions).find(id => id !== newLearningLanguage) || '';
      setNativeLanguage(newNativeLanguage);
      i18n.changeLanguage(newNativeLanguage);
    }
    resetBookSelection();
  }, [nativeLanguage, languageOptions, i18n]);

  const handleSearchChange = useCallback((e) => {
    logger.debug(`Search: ${e.target.value}`);
    setSearch(e.target.value);
    setPage(1); // Reset to first page when search changes
  }, []);

  const getDifficultyColor = useCallback((level) => {
    const colors = {
      '0': '#66BB6A', // Green for easiest
      '1': '#26C6DA', // Light blue
      '2': '#FFA726', // Orange
      '3': '#5C6BC0', // Blue
      '4': '#AB47BC', // Purple
      '5': '#EC407A'  // Pink for hardest
    };
    return colors[level] || '#9E9E9E';
  }, []);

  const handleMouseDown = useCallback((e) => {
    e.preventDefault();
    setIsResizing(true);
  }, []);

  const toggleSettings = useCallback(() => {
    if (isCollapsed) {
      setIsCollapsed(false);
      setSidebarWidth(350);
    }
    const newShowSettings = !showSettings;
    setShowSettings(newShowSettings);
    setShowProfile(false);
    
    const currentParams = new URLSearchParams(location.search);
    const newPath = newShowSettings ? '/settings' : '/';
    navigate(`${newPath}${currentParams.toString() ? '?' + currentParams.toString() : ''}`, { replace: true });
  }, [showSettings, navigate, location.search, isCollapsed]);

  const toggleProfile = useCallback(() => {
    if (isCollapsed) {
      setIsCollapsed(false);
      setSidebarWidth(350);
    }
    const newShowProfile = !showProfile;
    setShowProfile(newShowProfile);
    setShowSettings(false);
    
    const currentParams = new URLSearchParams(location.search);
    const newPath = newShowProfile ? '/profile' : '/';
    navigate(`${newPath}${currentParams.toString() ? '?' + currentParams.toString() : ''}`, { replace: true });
  }, [showProfile, navigate, location.search, isCollapsed]);

  const toggleBooks = useCallback(() => {
    if (isCollapsed) {
      setIsCollapsed(false);
      setSidebarWidth(350);
    }
    setShowProfile(false);
    setShowSettings(false);
    
    const currentParams = new URLSearchParams(location.search);
    navigate(`/${currentParams.toString() ? '?' + currentParams.toString() : ''}`, { replace: true });
  }, [isCollapsed, navigate]);

  const fetchUserSubscription = useCallback(async () => {
    // Skip if no user profile
    if (!userProfile) return;
    
    try {
      const response = await apiRequest('GET', 'USER_SUBSCRIPTION');
      setUserSubscription(response);
    } catch (error) {
      logger.error('Error fetching user subscription:', error);
    }
  }, [logger, userProfile]);

  const handleLogin = useCallback(async (email, isSubscribeFlow) => {
    try {
      const response = await apiRequest('POST', 'LOGIN', {}, {}, { email, subscribe: isSubscribeFlow });
      if (response) {
        logger.info('Magic link request successful');
        return { success: true, message: response.message };
      }
    } catch (error) {
      logger.error('Error requesting magic link:', error);
      throw error;
    }
  }, [logger]);

  const handleLogout = useCallback(async () => {
    try {
      const response = await apiRequest('POST', 'LOGOUT');
      if (response) {
        onLogout();
      }
      // Clear other user-related states
      setFavoriteBooks([]);
      setUserSubscription(null);
    } catch (error) {
      logger.error('Error logging out:', error);
    }
  }, [logger, onLogout]);

  useEffect(() => {
    fetchLanguageOptions();
  }, []);

  // Update the fetchFavoriteBooks function
  const fetchFavoriteBooks = useCallback(async () => {
    // Show promotion modal for non-premium users
    if (!userProfile || userProfile.subscription_tier !== 'premium') {
      setIsProductPromotionModalOpen(true);
      return;
    }
    
    try {
      const response = await apiRequest('GET', 'USER_FAVORITES', {}, {});
      setFavoriteBooks(response.map(book => book.id));
    } catch (error) {
      logger.error('Error fetching favorite books:', error);
      // Optionally show an error toast/notification here
    }
  }, [userProfile, logger]);

  useEffect(() => {
    if (userProfile && userProfile.subscription_tier === 'premium') {
      logger.info('User profile:', userProfile);
      fetchFavoriteBooks();
    }
  }, [userProfile]);

  const getDifficultyLevelValue = useCallback((level) => {
    const difficultyMap = {
      [t('difficulty.beginner')]: 1,
      [t('difficulty.intermediate')]: 2,
      [t('difficulty.advanced')]: 3,
      [t('difficulty.fluent')]: 4
    };
    return difficultyMap[level] || '';
  }, [t]);

  // Update the admin mode check
  useEffect(() => {
    const checkAdminModeStatus = () => {
      setIsAdminMode(checkAdminMode());
    };

    // Check initially and set up interval
    checkAdminModeStatus();
    const interval = setInterval(checkAdminModeStatus, 1000);
    
    return () => clearInterval(interval);
  }, []);

  // Add some debug logging
  useEffect(() => {
    logger.info('Admin mode status:', isAdminMode);
  }, [isAdminMode, logger]);

  // Update the fetchBooks function
  const fetchBooks = useCallback(async () => {
    if (!nativeLanguage) {
      return;
    }
    setIsLoading(true);
    try {
      const response = await apiRequest('GET', 'BOOKS', {}, {
        page,
        per_page: 2,
        difficulty_level: getDifficultyLevelValue(difficultyLevel),
        sort_by: sortBy,
        native_language: nativeLanguage,
        search: search,
        only_bookmarks: showBookmarkedOnly,
        category_id: category,
        active: showDeactivatedBooks ? false : true,
      });
      logger.debug('Books fetched:', response);
      setBooks(response.books);
      setTotalPages(response.total_pages);

      // Ensure current page is not greater than total pages
      if (page > response.total_pages) {
        setPage(Math.max(1, response.total_pages));
      }
    } catch (error) {
      logger.error('Error fetching books:', error);
    } finally {
      setIsLoading(false);
    }
  }, [nativeLanguage, page, difficultyLevel, sortBy, search, showBookmarkedOnly, category, showDeactivatedBooks]);

  useEffect(() => {
    fetchBooks();
  }, [page, difficultyLevel, sortBy, nativeLanguage, search, showBookmarkedOnly, category, showDeactivatedBooks]);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isResizing) return;
      let newWidth;
      if (textDirection === 'rtl') {
        newWidth = Math.min(
          Math.max(50, window.innerWidth - e.clientX),
          window.innerWidth * 0.8
        );
      } else {
        newWidth = Math.min(
          Math.max(50, e.clientX - sidebarRef.current.getBoundingClientRect().left),
          window.innerWidth * 0.8
        );
      }
      setSidebarWidth(newWidth);
      setIsCollapsed(newWidth <= 100);
    };

    const handleMouseUp = () => {
      setIsResizing(false);
    };

    if (isResizing) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing, textDirection]);

  // Update the handleFavoriteClick function
  const handleFavoriteClick = useCallback(async (bookId, event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!userProfile || userProfile.subscription_tier !== 'premium') {
      setIsProductPromotionModalOpen(true);
      return;
    }

    setIsFavoritesLoading(prev => ({ ...prev, [bookId]: true }));
    
    try {
      if (favoriteBooks.includes(bookId)) {
        await apiRequest('DELETE', 'USER_FAVORITE', { book_id: bookId });
        setFavoriteBooks(prev => prev.filter(id => id !== bookId));
      } else {
        await apiRequest('POST', 'USER_FAVORITES', {}, {}, { book_id: bookId });
        setFavoriteBooks(prev => [...prev, bookId]);
      }
    } catch (error) {
      logger.error('Error updating favorite status:', error);
      // Optionally show an error toast/notification here
    } finally {
      setIsFavoritesLoading(prev => ({ ...prev, [bookId]: false }));
    }
  }, [favoriteBooks, logger, userProfile]);

  useEffect(() => {
    if (Object.keys(languageOptions).length > 0 && isInitialLoad) {
      detectUserLocale();
      onLanguageOptionsChange(languageOptions);
    }
  }, [languageOptions, isInitialLoad, onLanguageOptionsChange]);

  const detectUserLocale = useCallback(() => {
    logger.info('Detecting user locale');
    if (!nativeLanguage) {
      const browserLang = navigator.language.split('-')[0];
      const detectedLanguage = Object.keys(languageOptions).find(id => id === browserLang) || Object.keys(languageOptions)[0];
      logger.info('Detected user language:', detectedLanguage);
      setNativeLanguage(detectedLanguage);
      setUserLocaleHeader(detectedLanguage);
      i18n.changeLanguage(detectedLanguage);
  
      if (!learningLanguage) {
        const defaultLearning = Object.keys(languageOptions).find(id => id !== detectedLanguage) || Object.keys(languageOptions)[1];
        setLearningLanguage(defaultLearning);
      }
    }else{
      i18n.changeLanguage(nativeLanguage);
      setUserLocaleHeader(nativeLanguage);
    }
    setIsInitialLoad(false);
  }, [nativeLanguage, learningLanguage, languageOptions, i18n, logger, setUserLocaleHeader]);

  const fetchLanguageOptions = useCallback(async () => {
    try {
      const response = await apiRequest('GET', 'LANGUAGE_OPTIONS');
      logger.info('Got language options:', response);
      setLanguageOptions(response);
    } catch (error) {
      logger.error('Error fetching language options:', error);
    }
  }, [logger]);

  useEffect(() => {
    if (!isInitialLoad) {
      const nativeLanguageDirection = languageOptions[nativeLanguage]?.direction || 'ltr';
      const learningLanguageDirection = languageOptions[learningLanguage]?.direction || 'ltr';
      onLanguageSelect(nativeLanguage, learningLanguage, nativeLanguageDirection, learningLanguageDirection);
      // Update text direction based on native language
      setTextDirection(nativeLanguageDirection);
    }
  }, [nativeLanguage, learningLanguage, isInitialLoad, languageOptions, onLanguageSelect]);

  // Update handleBookClick to execute immediately but prevent rapid re-clicks
  const handleBookClick = useCallback((bookId) => {
    // If there's a pending debounce timer, ignore this click
    if (debounceTimerRef.current) {
      return;
    }

    // Execute the action immediately
    const clickedBook = books.find(book => book.id === bookId);
    setSelectedBookId(bookId);
    setCurrentBook(clickedBook);
    onBookSelect(bookId, nativeLanguage, learningLanguage);
    
    // Set a debounce timer to prevent rapid re-clicks
    debounceTimerRef.current = setTimeout(() => {
      debounceTimerRef.current = null;
    }, 1000);
  }, [onBookSelect, nativeLanguage, learningLanguage, books]);

  // Add cleanup effect
  useEffect(() => {
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, []);

  const handlePageChange = useCallback((newPage) => {
    setPage(Math.min(Math.max(1, newPage), totalPages));
  }, [totalPages]);

  // Add effect to handle initial URL path
  useEffect(() => {
    const path = location.pathname;
    if (path === '/profile') {
      setShowProfile(true);
      setShowSettings(false);
    } else if (path === '/settings') {
      setShowSettings(true);
      setShowProfile(false);
    }
  }, [location.pathname]);

  // Add effect to fetch book details when selectedBookId changes
  useEffect(() => {
    const fetchCurrentBook = async () => {
      if (!selectedBookId || !nativeLanguage) return;
      
      try {
        logger.info('Fetching current book with id:', selectedBookId);
        const response = await apiRequest('GET', 'BOOK', 
          { book_id: selectedBookId },
          { language: nativeLanguage }
        );
        logger.info('Current book fetched:', response);
        setCurrentBook(response);
      } catch (error) {
        logger.error('Error fetching current book:', error);
      }
    };

    fetchCurrentBook();
  }, [selectedBookId, nativeLanguage]);

  const toggleCollapse = useCallback(() => {
    setIsCollapsed(prev => !prev);
    if (!isCollapsed) {
      setSidebarWidth(50); // Collapsed width
    } else {
      setSidebarWidth(350); // Default expanded width
    }
  }, [isCollapsed]);

  // Update the handleBookmarkClick function
  const handleBookmarkClick = useCallback(async (bookId, event) => {
    logger.info('Handling bookmark click for bookId:', bookId);
    event.preventDefault();
    event.stopPropagation();

    if (!userProfile || userProfile.subscription_tier !== 'premium') {
      logger.info('User is not premium, opening product promotion modal');
      setIsProductPromotionModalOpen(true);
      return;
    }

    const bookmark = bookmarks.find(b => b.book_id === bookId);
    if (bookmark) {
      logger.info('Bookmark found, updating selected book and calling onBookmarkClick');
      // Update the selected book states before navigating
      setSelectedBookId(bookId);
      const clickedBook = books.find(book => book.id === bookId);
      setCurrentBook(clickedBook);
      
      // Ensure both bookId and pageId are valid before calling onBookmarkClick
      if (bookId && bookmark.page_id) {
        logger.info('Navigating to bookmarked page:', { bookId, pageId: bookmark.page_id });
        onBookmarkClick(bookId, bookmark.page_id);
      } else {
        logger.error('Invalid bookmark data:', { bookId, bookmark });
      }
    } else {
      logger.error('No bookmark found for book:', bookId);
    }
  }, [bookmarks, onBookmarkClick, userProfile, books]);

  // Filter books based on bookmark status
  const filteredBooks = books;

  // Update the language selectors JSX
  const renderLanguageSelectors = () => (
    <div className="language-selectors">
      <div className="language-selector">
        <label htmlFor="native-language">{t('menu.nativeLanguage')}</label>
        <select
          id="native-language"
          value={nativeLanguage}
          onChange={handleNativeLanguageChange}
          className="filter-select"
        >
          {Object.entries(languageOptions).map(([id, data]) => (
            <option key={id} value={id}>{data.name}</option>
          ))}
        </select>
      </div>
      <div className="language-selector">
        <label htmlFor="learning-language">{t('menu.learningLanguage')}</label>
        <select
          id="learning-language"
          value={learningLanguage}
          onChange={handleLearningLanguageChange}
          onClick={handleLearningLanguageInteraction}
          onMouseDown={handleLearningLanguageInteraction}
          className={`filter-select ${(!userProfile || userProfile.subscription_tier !== 'premium') ? 'disabled' : ''}`}
        >
          {sortedLearningLanguages.map(lang => (
            <option key={lang.id} value={lang.id}>{t(`languages.${lang.id}`)}</option>
          ))}
        </select>
      </div>
    </div>
  );

  // Add effect to sync with prop changes
  useEffect(() => {
    if (initialSelectedBookId) {
      setSelectedBookId(initialSelectedBookId);
    }
  }, [initialSelectedBookId]);

  // Helper function to get category level order
  const getCategoryLevelOrder = useCallback((level) => {
    const levelOrder = {
      'beginner': 0,
      'intermediate': 1,
      'advanced': 2,
      'fluent': 3
    };
    return levelOrder[level] ?? 999; // Default to high number for unknown levels
  }, []);

  // Sort and memoize categories
  const sortedCategories = useMemo(() => {
    return [...categories].sort((a, b) => {
      const [levelA] = a.name.split('.');
      const [levelB] = b.name.split('.');
      
      // First sort by level
      const levelOrderDiff = getCategoryLevelOrder(levelA) - getCategoryLevelOrder(levelB);
      if (levelOrderDiff !== 0) return levelOrderDiff;
      
      // Then sort alphabetically by translated name within each level
      return getCategoryTranslation(a.name).localeCompare(getCategoryTranslation(b.name), i18n.language);
    });
  }, [categories, getCategoryLevelOrder, getCategoryTranslation, i18n.language]);

  const getDifficultyLabel = useCallback((level) => {
    const difficultyMap = {
      '0': 'A1',
      '1': 'A2', 
      '2': 'B1',
      '3': 'B2',
      '4': 'C1',
      '5': 'C2'
    };
    return difficultyMap[level] || level;
  }, []);

  // Add new helper function to group categories by level
  const groupCategoriesByLevel = useCallback((categories) => {
    return categories.reduce((acc, category) => {
      const [level] = category.name.split('.');
      if (!acc[level]) {
        acc[level] = [];
      }
      acc[level].push(category);
      return acc;
    }, {});
  }, []);

  // Update function to handle activation toggle
  const handleActivationToggle = useCallback(async (bookId, currentActive, event) => {
    event.preventDefault();
    event.stopPropagation();

    try {
      // If showing active books (showDeactivatedBooks is false), always deactivate
      const newActiveState = showDeactivatedBooks ? !currentActive : false;
      
      const response = await apiRequest('PATCH', 'BOOK_ACTIVE', { book_id: bookId }, {}, {
        active: newActiveState
      });
      
      // Refresh the books list to show updated status
      fetchBooks();
      
      logger.info('Book activation status updated:', response);
    } catch (error) {
      logger.error('Error updating book activation status:', error);
    }
  }, [fetchBooks, logger, showDeactivatedBooks]);

  return (
    <div 
      className={`books-browser ${open} ${isResizing ? 'resizing' : ''} ${isCollapsed ? 'collapsed' : ''}`}
      ref={sidebarRef}
      style={{ width: `${sidebarWidth}px`, direction: textDirection }}
    >
      <div className="books-browser-header">
        {isCollapsed ? (
          <>
            <Book
              className="book-icon"
              onClick={toggleBooks}
              size={24}
              color="var(--text-color)"
            />
            <User
              className="profile-icon"
              onClick={toggleProfile}
              size={24}
              color="var(--text-color)"
            />
            <SettingsIcon
              className="settings-icon"
              onClick={toggleSettings}
              size={24}
              color="var(--text-color)"
            />
          </>
        ) : (
          <>
            <User
              className={`profile-icon ${showProfile ? 'active' : ''}`}
              onClick={toggleProfile}
              size={24}
              color="var(--text-color)"
            />
            <p 
              className="books-browser-title" 
              dir='ltr'
              onClick={toggleBooks}
              style={{ cursor: 'pointer' }}
            >
              All Books, All Languages!
            </p>
            <SettingsIcon
              className={`settings-icon ${showSettings ? 'active' : ''}`}
              onClick={toggleSettings}
              size={24}
              color="var(--text-color)"
            />
          </>
        )}
      </div>
      {!isCollapsed && (
        <>
          {showSettings ? (
            <Settings
              currentTheme={currentTheme}
              onThemeChange={onThemeChange}
              textAlignment={textAlignment}
              onTextAlignmentChange={onTextAlignmentChange}
              dyslexicFontEnabled={dyslexicFontEnabled}
              onDyslexicFontToggle={onDyslexicFontToggle}
              onOpenCookiePreferences={onOpenCookiePreferences}
              userSubscription={userSubscription}
              userProfile={userProfile}
            />
          ) : showProfile ? (
            <Profile
              handleLogin={handleLogin}
              handleLogout={handleLogout}
              signupErrorMessage={signupErrorMessage}
              userProfile={userProfile}
              onRefetchProfile={onRefetchProfile}
              shouldTriggerSubscribe={shouldTriggerSubscribe}
            />
          ) : (
            <>
              {renderLanguageSelectors()}
              <div className="books-browser-subtitle">
                <label>{t('menu.pick_a_book')}</label>
              </div>
              <div className="search-bar">
                <input
                  type="text"
                  placeholder={t('menu.search')}
                  value={search}
                  onChange={handleSearchChange}
                  className="search-input"
                />
              </div>
              <div className="books-filters">
                {/* Add admin checkbox before other filters */}
                {isAdminMode && (
                  <label className="admin-filter-checkbox">
                    <input
                      type="checkbox"
                      checked={showDeactivatedBooks}
                      onChange={(e) => setShowDeactivatedBooks(e.target.checked)}
                    />
                    {t('menu.showDeactivatedBooks', 'Show Deactivated Books')}
                  </label>
                )}
                <select 
                  value={difficultyLevel} 
                  onChange={(e) => setDifficultyLevel(e.target.value)}
                  className="filter-select"
                >
                  <option value="">
                    {difficultyLevel ? t('menu.removeFilter') : t('menu.filterByLevel')}
                  </option>
                  <option value={t('difficulty.beginner')}>{t('difficulty.beginner')}</option>
                  <option value={t('difficulty.intermediate')}>{t('difficulty.intermediate')}</option>
                  <option value={t('difficulty.advanced')}>{t('difficulty.advanced')}</option>
                  <option value={t('difficulty.fluent')}>{t('difficulty.fluent')}</option>
                </select>
                <select 
                  value={category} 
                  onChange={(e) => setCategory(e.target.value)}
                  className="filter-select"
                >
                  <option value="">
                    {category ? t('menu.removeFilter') : t('menu.filterByCategory')}
                  </option>
                  {Object.entries(groupCategoriesByLevel(sortedCategories)).map(([level, cats]) => (
                    <React.Fragment key={level}>
                      <option 
                        disabled 
                        className="category-heading"
                        style={{
                          backgroundColor: getDifficultyColor(
                            level === 'beginner' ? '0' : 
                            level === 'intermediate' ? '2' :
                            level === 'advanced' ? '4' :
                            level === 'fluent' ? '5' : '0'
                          )
                        }}
                      >
                        {t(`difficulty.${level}`)}
                      </option>
                      {cats.map((category) => (
                        <option key={category.id} value={category.id}>
                          {getCategoryTranslation(category.name)}
                        </option>
                      ))}
                    </React.Fragment>
                  ))}
                </select>
                <select 
                  value={sortBy} 
                  onChange={(e) => setSortBy(e.target.value)}
                  className="filter-select"
                >
                  <option value="difficulty_level">{t('menu.sortByLevel')}</option>
                  <option value="title">{t('menu.sortByTitle')}</option>
                </select>
                <button
                  className={`bookmark-filter-button ${(!userProfile || userProfile.subscription_tier !== 'premium') ? 'disabled' : ''}`}
                  onClick={(e) => {
                    if (!userProfile || userProfile.subscription_tier !== 'premium') {
                      e.preventDefault();
                      setIsProductPromotionModalOpen(true);
                      return;
                    }
                    setShowBookmarkedOnly(!showBookmarkedOnly);
                  }}
                  data-active={showBookmarkedOnly}
                >
                  <Bookmark size={18} />
                  {showBookmarkedOnly ? t('menu.showAllBooks') : t('menu.showBookmarkedOnly')}
                </button>
              </div>
              <div className="books-list">
                {isLoading ? (
                  <div className="loading-animation">
                    <div className="spinner"></div>
                    <p>{t('menu.loadingBooks')}</p>
                  </div>
                ) : (
                  <>
                    {/* Show current book if it exists and isn't in the current page */}
                    {currentBook && !books.find(book => book.id === currentBook.id) && (
                      <div 
                        key={currentBook.id} 
                        onClick={() => handleBookClick(currentBook.id)} 
                        className={`book-card current-book minimized selected`}
                        data-current-label={t('menu.currentBook')}
                      >
                        <h3 className="book-title">{currentBook.title}</h3>
                      </div>
                    )}
                    
                    {/* Existing books list */}
                    {filteredBooks.map((book) => (
                      <div 
                        key={book.id} 
                        onClick={() => handleBookClick(book.id)} 
                        className={`book-card ${book.id === selectedBookId ? 'selected' : ''}`}
                        data-selected-label={t('menu.currentBook')}
                      >
                        <h3 
                          className="book-title" 
                          title={book.title}
                        >
                          {book.title.length > 50 ? `${book.title.substring(0, 50)}...` : book.title}
                        </h3>
                        <div className="book-meta">
                          <p className="book-summary">{book.summary}</p>
                        </div>
                        <div className="book-stats">
                          <span 
                            className="difficulty-badge" 
                            style={{backgroundColor: getDifficultyColor(book.difficulty_level)}}
                          >
                            {getDifficultyLabel(book.difficulty_level)}
                          </span>
                          {/* Add activation toggle button for admin mode */}
                          {isAdminMode && (
                            <button
                              className={`activation-toggle ${book.active ? 'active' : 'inactive'}`}
                              onClick={(e) => handleActivationToggle(book.id, book.active, e)}
                              title={book.active ? t('menu.deactivateBook') : t('menu.activateBook')}
                            >
                              {book.active ? '✓' : '✗'}
                            </button>
                          )}
                          {bookmarks.some(b => b.book_id === book.id) && (
                            <Bookmark
                              className="bookmark-icon bookmarked"
                              onClick={(e) => handleBookmarkClick(book.id, e)}
                              size={24}
                              color="var(--accent-color)"
                              fill="var(--accent-color)"
                              data-tooltip={t('menu.goToBookmark')}
                            />
                          )}
                          <Heart
                            className={`favorite-icon ${favoriteBooks.includes(book.id) ? 'favorite' : ''} ${isFavoritesLoading[book.id] ? 'loading' : ''}`}
                            onClick={(e) => handleFavoriteClick(book.id, e)}
                            size={24}
                            color={favoriteBooks.includes(book.id) ? 'red' : 'gray'}
                            fill={favoriteBooks.includes(book.id) ? 'red' : 'none'}
                          />
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
              <div className="pagination">
                <button 
                  onClick={() => handlePageChange(page - 1)} 
                  disabled={page === 1}
                  className="pagination-button"
                  aria-label={t('menu.previousPage')}
                >
                  {textDirection === 'rtl' ? <ChevronRight size={20} /> : <ChevronLeft size={20} />}
                </button>
                <span className="page-indicator">
                  {t('menu.pageIndicator', { currentPage: Math.min(page, totalPages), total: totalPages })}
                </span>
                <button 
                  onClick={() => handlePageChange(page + 1)} 
                  disabled={page >= totalPages}
                  className="pagination-button"
                  aria-label={t('menu.nextPage')}
                >
                  {textDirection === 'rtl' ? <ChevronLeft size={20} /> : <ChevronRight size={20} />}
                </button>
              </div>
            </>
          )}
        </>
      )}
      <div 
        className={`resize-handle ${textDirection === 'rtl' ? 'rtl' : ''}`}
        onMouseDown={handleMouseDown}
        onDoubleClick={toggleCollapse}
      />
      {isLoginModalOpen && (
        <Modal onClose={() => setIsLoginModalOpen(false)}>
          <h2>{t('menu.loginToSubscribe.title')}</h2>
          <p>{t('menu.loginToSubscribe.message')}</p>
        </Modal>
      )}
      {/* Add ProductPromotionModal */}
      <ProductPromotionModal
        isOpen={isProductPromotionModalOpen}
        onClose={() => setIsProductPromotionModalOpen(false)}
      />
    </div>
  );
};

export default React.memo(Menu);
