import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import ParallelTextHistory from './ParallelTextHistory';
import { useLogger } from '../hooks/useLogger';
import './css/ParallelTextViewer.css';
import Toolbar from './Toolbar';
import Menu from './Menu';
import './css/common.css';
import './css/themes.css';
import { useTranslation } from 'react-i18next';
import { apiRequest } from '../config/api.js';
import i18n from '../i18n';
// import WordDetailsModal from './WordDetailsModal';
import ThemePickerWelcomeScreen from './ThemePickerWelcomeScreen';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingProgress from './LoadingProgress';
import ReactDOM from 'react-dom';

// Add this new component at the top level, outside of ParallelTextViewer
const LoadingDots = ({ direction }) => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots(prev => {
        if (direction === 'rtl') {
          // RTL animation
          switch (prev) {
            case '': return '.';
            case '.': return '..';
            case '..': return '...';
            default: return '';
          }
        } else {
          // LTR animation
          switch (prev) {
            case '': return '.';
            case '.': return '..';
            case '..': return '...';
            default: return '';
          }
        }
      });
    }, 500);

    return () => clearInterval(interval);
  }, [direction]);

  return <span className="loading-dots">{dots}</span>;
};

const ParallelTextViewer = ({ 
  languageId, 
  onLanguageChange, 
  openCookiePreferences, 
  magicLinkStatus, 
  shouldTriggerSubscribe, 
  onSubscribeTriggered,
  userProfile,
  isLoadingProfile,
  onRefetchProfile
}) => {
  const { t } = useTranslation();

  const [bookId, setBookId] = useState('');
  const [bookDetails, setBookDetails] = useState(null);
  const [currentPageId, setCurrentPageId] = useState(null);
  const [currentPage, setCurrentPage] = useState("");
  const [pageContent, setPageContent] = useState(null);
  const [pagePronunciation, setPagePronunciation] = useState(null);
  const [pageFurigana, setPageFurigana] = useState(null);
  const [showFurigana, setShowFurigana] = useState(true);
  const [error, setError] = useState('');
  const [menuOpen, setMenuOpen] = useState(true);
  const [hoveredWord, setHoveredWord] = useState(null);

  const [textAlignment, setTextAlignment] = useState(() => {
    return localStorage.getItem('textAlignment') || 'center';
  });
  const [dyslexicFontEnabled, setDyslexicFontEnabled] = useState(() => {
    return localStorage.getItem('dyslexicFontEnabled') === 'true';
  });
  const [nativeLanguage, setNativeLanguage] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('n') || localStorage.getItem('nativeLanguage') || "";
  });
  const [learningLanguage, setLearningLanguage] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('l') || localStorage.getItem('learningLanguage') || "";
  });
  const [nativeLanguageDirection, setNativeLanguageDirection] = useState(() => {
    return localStorage.getItem('nativeLanguageDirection') || 'ltr';
  });
  const [learningLanguageDirection, setLearningLanguageDirection] = useState(() => {
    return localStorage.getItem('learningLanguageDirection') || 'ltr';
  });

  const [isLoadingBook, setIsLoadingBook] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const abortControllerRef = useRef(null);
  const [showNativeLanguage, setShowNativeLanguage] = useState(true);
  const [showPhonetics, setShowPronunciation] = useState(true);
  const [pageAssociations, setPageAssociations] = useState(null);
  const [showThemePicker, setShowThemePicker] = useState(false);
  const [currentTheme, setCurrentTheme] = useState('Classic');
  const [themeLoaded, setThemeLoaded] = useState(false);

  const [selectedWord, setSelectedWord] = useState(null);
  // const [isWordDetailsModalOpen, setIsWordDetailsModalOpen] = useState(false);
  const [selectedWordDetails, setSelectedWordDetails] = useState(null);

  const [pageNumberInput, setPageNumberInput] = useState('');
  const [isPageNumberChanged, setIsPageNumberChanged] = useState(false);

  const logger = useLogger('ParallelTextViewer');

  const location = useLocation();
  const navigate = useNavigate();

  const [validLanguageOptions, setValidLanguageOptions] = useState([]);

  const [urlParamsProcessed, setUrlParamsProcessed] = useState(false);

  const [initialPageId, setInitialPageId] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const [nativeContent, setNativeContent] = useState(null);
  const [learningContent, setLearningContent] = useState(null);
  const [associations, setAssociations] = useState(null);

  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingLabel, setLoadingLabel] = useState('');

  const [isProductPromotionModalOpen, setIsProductPromotionModalOpen] = useState(false);

  const [bookmarks, setBookmarks] = useState([]);
  const [currentBookmark, setCurrentBookmark] = useState(null);

  const isPremiumUser = userProfile?.subscription_tier === 'premium';

  const [textSize, setTextSize] = useState(() => {
    return parseInt(localStorage.getItem('textSize')) || 100;
  });

  const fetchPage = async (pageId) => {
    if (!bookId || !pageId) {
      logger.error('fetchPage called with invalid parameters:', { bookId, pageId });
      return;
    }

    logger.info('fetchPage called with:', { bookId, pageId });

    // Reset all states at the start
    setNativeContent(null);
    setLearningContent(null);
    setAssociations(null);
    setPagePronunciation(null);
    setPageFurigana(null);
    
    abortControllerRef.current = new AbortController();

    try {
      setIsLoadingPage(true);
      const cdnDomain = process.env.REACT_APP_CDN_SUBDOMAIN;
      const artificialDelay = 150;

      // Stage 1: Get initial responses with content paths
      const nativeResponse = await apiRequest(
        'GET',
        'BOOK_PAGE',
        { book_id: bookId, page_id: pageId },
        { native_language: nativeLanguage },
        null,
        abortControllerRef.current.signal
      );

      // Stage 2: Fetch native content from CDN
      await new Promise(resolve => setTimeout(resolve, artificialDelay));
      const nativeContent = await fetch(`https://${cdnDomain}/${nativeResponse.content_path}`)
        .then(res => {
          if (!res.ok) throw new Error(`Native content fetch failed: ${res.status}`);
          return res.text();
        })
        .then(text => JSON.parse(text));

      setNativeContent(nativeContent.content);
      setCurrentPage(nativeResponse.page_number);
      setCurrentPageId(pageId);

      // Use passed userProfile prop instead of fetching
      if (learningLanguage) {
        const isPremiumEnabled = process.env.REACT_APP_DISABLE_PREMIUM_GATES === 'true' || 
                               (userProfile?.subscription_tier === 'premium');

        if (isPremiumEnabled) {
          // First fetch learning content
          const learningResponse = await apiRequest(
            'GET',
            'BOOK_PAGE',
            { book_id: bookId, page_id: pageId },
            { learning_language: learningLanguage },
            null,
            abortControllerRef.current.signal
          );

          // Fetch learning content from CDN
          const learningContentData = await fetch(`https://${cdnDomain}/${learningResponse.content_path}`)
            .then(res => {
              if (!res.ok) throw new Error(`Learning content fetch failed: ${res.status}`);
              return res.text();
            })
            .then(text => JSON.parse(text));

          setLearningContent(learningContentData.content);

          // Now that learning content exists, fetch associations
          const associationsResponse = await apiRequest(
            'GET',
            'BOOK_PAGE_ASSOCIATIONS',
            { book_id: bookId, page_id: pageId },
            { native_language: nativeLanguage, learning_language: learningLanguage },
            null,
            abortControllerRef.current.signal
          );

          const associationsData = await fetch(`https://${cdnDomain}/${associationsResponse.association_path}`)
            .then(res => {
              if (!res.ok) throw new Error(`Associations fetch failed: ${res.status}`);
              return res.text();
            })
            .then(text => JSON.parse(text));

          setAssociations(associationsData);

          // Stage 6: Get and fetch pronunciation
          await new Promise(resolve => setTimeout(resolve, artificialDelay));
          const pronunciationResponse = await apiRequest(
            'GET',
            'BOOK_PAGE_PRONUNCIATION',
            { book_id: bookId, page_id: pageId },
            { native_language: nativeLanguage, learning_language: learningLanguage },
            null,
            abortControllerRef.current.signal
          );

          await new Promise(resolve => setTimeout(resolve, artificialDelay));
          const pronunciation = await fetch(`https://${cdnDomain}/${pronunciationResponse.path}`)
            .then(res => {
              if (!res.ok) throw new Error(`Pronunciation fetch failed: ${res.status}`);
              return res.text();
            })
            .then(text => JSON.parse(text));

          setPagePronunciation(pronunciation);

          // Stage 7: Get and fetch furigana if learning Japanese
          if (learningLanguage === 'ja') {
            await new Promise(resolve => setTimeout(resolve, artificialDelay));
            const furiganaResponse = await apiRequest(
              'GET',
              'FURIGANA_TRANSCRIPTION',
              { book_id: bookId, page_id: pageId },
              { learning_language: learningLanguage }
            );

            await new Promise(resolve => setTimeout(resolve, artificialDelay));
            const furigana = await fetch(`https://${cdnDomain}/${furiganaResponse.path}`)
              .then(res => {
                if (!res.ok) throw new Error(`Furigana fetch failed: ${res.status}`);
                return res.text();
              })
              .then(text => JSON.parse(text));

            setPageFurigana(furigana);
          }
        }
      }

      if (!isInitialLoad) {
        const params = new URLSearchParams(window.location.search);
        if (bookId) params.set('b', bookId);
        if (nativeLanguage) params.set('n', nativeLanguage);
        if (learningLanguage) params.set('l', learningLanguage);
        params.set('p', pageId);
        navigate(`?${params.toString()}`, { replace: true });
      }

    } catch (error) {
      logger.error('001 - Error fetching page:', error);
      // if (axios.isCancel(error)) {
      //   logger.debug('Request canceled:', error.message);
      // } else {
      //   // TODO @Falimonda - fix all this; a failed request for auth profile just means the user isn't logged in 
      //   if (error.response?.status !== 401 && !error.response?.responseURL?.includes('auth/profile')) {
      //     setError(t('error.fetchPage'));
          
      //   }
      // }
    } finally {
      setIsLoadingPage(false);
    }
  };
  
  const loadBookPage = useCallback(async (bookId, pageId) => {
    logger.info('loadBookPage called with:', { bookId, pageId, currentBookId: bookId });

    // Validate inputs
    if (!bookId || !pageId) {
      logger.error('Invalid bookId or pageId:', { bookId, pageId });
      return;
    }

    // Compare with current bookId from state
    if (bookId === bookId) {
      logger.info('Book already loaded, fetching new page:', pageId);
      await fetchPage(pageId);
    } else {
      // Otherwise, set both book and initial page
      logger.info('Loading new book and page:', { bookId, pageId });
      setInitialPageId(pageId);
      setBookId(bookId);
    }
  }, [bookId, fetchPage]);

  const getURLWithParams = useCallback((path) => {
    const params = new URLSearchParams(location.search);
    return `${path}${params.toString() ? '?' + params.toString() : ''}`;
  }, [location.search]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const nLang = params.get('n');
    const lLang = params.get('l');
    const bId = params.get('b');
    const pId = params.get('p');

    // Batch state updates to prevent multiple re-renders
    ReactDOM.unstable_batchedUpdates(() => {
      if (nLang) setNativeLanguage(nLang);
      if (lLang && (nLang !== lLang)) {
        if (!userProfile || userProfile.subscription_tier !== 'premium') {
          params.delete('l');
          navigate(`?${params.toString()}`, { replace: true });
          setIsProductPromotionModalOpen(true);
        } else {
          setLearningLanguage(lLang);
        }
      }
      // Only set book and page ID if we haven't processed URL params yet
      if (!urlParamsProcessed) {
        if (bId) setBookId(bId);
        if (pId) {
          setInitialPageId(pId);
          setCurrentPageId(pId);
        }
      }
      setUrlParamsProcessed(true);
    });
  }, [validLanguageOptions, userProfile]);

  useEffect(() => {
    if (urlParamsProcessed && !isInitialLoad) {
      const params = new URLSearchParams(window.location.search);
      if (nativeLanguage) params.set('n', nativeLanguage);
      if (learningLanguage) params.set('l', learningLanguage);
      if (bookId) params.set('b', bookId);
      if (currentPageId) params.set('p', currentPageId);
      const newUrl = `?${params.toString()}`;
      if (newUrl !== window.location.search) {
        navigate(newUrl, { replace: true });
      }
    }
  }, [nativeLanguage, learningLanguage, bookId, currentPageId, urlParamsProcessed, isInitialLoad]);

  const handleLanguageOptionsChange = (options) => {
    logger.info('Language options:', options);
    if (options && Object.keys(options).length > 0) {
      const params = new URLSearchParams(location.search);
      const nLang = params.get('n');
      const lLang = params.get('l');
      const bId = params.get('b');
      const pId = params.get('p');

      logger.info('Params: Native language:', nLang);
      logger.info('Params: Learning language:', lLang);
      logger.info('Params: Book ID:', bId);
      logger.info('Params: Page ID:', pId);

      let nativeLanguageChanged = false;
      let learningLanguageChanged = false;
    
      if (nLang && options[nLang]) {
        setNativeLanguage(nLang);
        setNativeLanguageDirection(options[nLang].direction);
        nativeLanguageChanged = true;
      }
      if (lLang && options[lLang]) {
        setLearningLanguage(lLang);
        setLearningLanguageDirection(options[lLang].direction);
        learningLanguageChanged = true;
      }
      if (bId) {
        setBookId(bId);
      }
      if (pId && bId && (nativeLanguageChanged || learningLanguageChanged)) {
        fetchPage(pId);
      }
    
      setValidLanguageOptions(options);
    }
  }

  const handleLogin = () => {
    logger.info('User logged in');
    onRefetchProfile();
  };

  const handleSignup = () => {
    logger.info('User signed up');
    onRefetchProfile();
  };

  const handleLogout = () => {
    logger.info('User logged out');
    onRefetchProfile();
  };

  const handlePasswordReset = () => {
    logger.info('Password reset');
    onRefetchProfile();
  };

  const ClickableText = ({ text, onWordClick, language }) => {
    const words = text.split(' ');
    return (
      <span className="clickable-text">
        {words.map((word, index) => (
          <span
            key={index}
            className="hoverable-word"
            onClick={() => onWordClick(word)}
          >
            {word}
            {index < words.length - 1 ? ' ' : ''}
          </span>
        ))}
      </span>
    );
  };

  useEffect(() => {
    localStorage.setItem('textAlignment', textAlignment);
  }, [textAlignment]);

  useEffect(() => {
    localStorage.setItem('dyslexicFontEnabled', dyslexicFontEnabled);
  }, [dyslexicFontEnabled]);
  
  useEffect(() => {
    logger.debug('Setting local storage learningLanguage:', learningLanguage);
    if (learningLanguage && learningLanguage !== nativeLanguage) {
      localStorage.setItem('learningLanguage', learningLanguage);
    } else if (learningLanguage === nativeLanguage) {
      localStorage.removeItem('learningLanguage');
    }
  }, [learningLanguage, nativeLanguage]);
  
  useEffect(() => {
    logger.debug('Setting local storage nativeLanguage:', nativeLanguage);
    localStorage.setItem('nativeLanguage', nativeLanguage);
  }, [nativeLanguage]);

  useEffect(() => {
    localStorage.setItem('nativeLanguageDirection', nativeLanguageDirection);
  }, [nativeLanguageDirection]);

  useEffect(() => {
    localStorage.setItem('learningLanguageDirection', learningLanguageDirection);
  }, [learningLanguageDirection]);
  
  useEffect(() => {
    let isMounted = true;
    
    if (bookId && urlParamsProcessed) {
      // Clear states when loading a new book
      ReactDOM.unstable_batchedUpdates(() => {
        setNativeContent(null);
        setLearningContent(null);
        setAssociations(null);
        setPagePronunciation(null);
        setPageFurigana(null);
        setCurrentPage('');
        setCurrentPageId(null);
        setMenuOpen(false);
      });
      
      // Load the book only if component is still mounted
      if (isMounted) {
        if (initialPageId) {
          loadBookPage(bookId, initialPageId);
          setInitialPageId(null);
        } else {
          loadBook();
        }
      }
    }
    
    return () => {
      isMounted = false;
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [bookId, userProfile, learningLanguage, nativeLanguage, urlParamsProcessed]);

  useEffect(() => {
    setPageContent(null);
    setPagePronunciation(null);
    setBookDetails(null);
    setCurrentPageId(null);
    setCurrentPage("");
    setPageAssociations(null);
  }, [learningLanguage, nativeLanguage]);

  useEffect(() => {
    if (pageContent && learningLanguage === 'ja') {
      fetchFurigana();
    }
  }, [pageContent, learningLanguage]);

  const fetchFurigana = async () => {
    try {
      const response = await apiRequest('GET', 'FURIGANA_TRANSCRIPTION', 
        { book_id: bookId, page_id: currentPageId },
        { learning_language: learningLanguage }
      );
      setPageFurigana(response);
    } catch (error) {
      logger.error('Error fetching furigana:', error);
    }
  };

  const toggleFurigana = () => {
    setShowFurigana(prev => !prev);
  };

  const handleThemeSelect = (theme) => {
    logger.debug(`Theme selected: ${theme}`);
    setCurrentTheme(theme);
    document.documentElement.setAttribute('data-theme', theme.toLowerCase());
    localStorage.setItem('selectedTheme', theme);
    setShowThemePicker(false);
  };
  
  const closeThemePicker = () => {
    setShowThemePicker(false);
  };
  
  const loadBook = async () => {
    setIsLoadingBook(true);
    setError('');
    setBookDetails(null);
    setPageContent(null);
    setCurrentPageId(null);
    setPagePronunciation(null);
    
    try {
      const isPremiumUser = userProfile?.subscription_tier === 'premium';
      
      const languageToUse = isPremiumUser ? learningLanguage : nativeLanguage;
      
      const detailsResponse = await apiRequest('GET', 'BOOK', { book_id: bookId }, { 
        language: languageToUse,
      });
      setBookDetails(detailsResponse);
      
      await new Promise(resolve => setTimeout(resolve, 100));
      
      if (initialPageId) {
        fetchPage(initialPageId);
        setInitialPageId(null);
      } else {
        const firstPageResponse = await apiRequest('GET', 'BOOK_FIRST_PAGE', { book_id: bookId });
        if (firstPageResponse && firstPageResponse.page_id) {
          fetchPage(firstPageResponse.page_id);
        }
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        logger.debug('Request canceled:', error.message);
      } else {
        setError(t('error.loadBook'));
        logger.error('Error loading book:', error);
      }
    } finally {
      setIsLoadingBook(false);
      setIsInitialLoad(false);
    }
  };

  const fetchPronunciation = async (pageId) => {
    try {
      const response = await apiRequest('GET', 'BOOK_PAGE_PRONUNCIATION', 
        { book_id: bookId, page_id: pageId }, 
        { native_language: nativeLanguage, learning_language: learningLanguage }
      );
      setPagePronunciation(response);
    } catch (error) {
      if (axios.isCancel(error)) {
        logger.debug('Pronunciation request canceled:', error.message);
      } else {
        setError(t('error.fetchPronunciation'));
        logger.error('Error fetching pronunciation:', error);
      }
    }
  };

  // Add debounce ref for page navigation
  const pageNavigationDebounceRef = useRef(null);

  const handlePreviousPage = async () => {
    // If there's a pending debounce timer, ignore this click
    if (pageNavigationDebounceRef.current) {
      return;
    }

    try {
      const response = await apiRequest('GET', 'BOOK_PREVIOUS_PAGE', { book_id: bookId, page_id: currentPageId });
      if (response.previous_page_id) {
        await fetchPage(response.previous_page_id);
      } else {
        setError(t('error.firstPage'));
        setTimeout(() => setError(''), 1500);
      }
    } catch (error) {
      setError(t('error.fetchPreviousPage'));
      logger.error('Error fetching previous page:', error);
    }

    // Set a debounce timer to prevent rapid re-clicks
    pageNavigationDebounceRef.current = setTimeout(() => {
      pageNavigationDebounceRef.current = null;
    }, 1000);
  };

  const handleNextPage = async () => {
    // If there's a pending debounce timer, ignore this click
    if (pageNavigationDebounceRef.current) {
      return;
    }

    try {
      const response = await apiRequest('GET', 'BOOK_NEXT_PAGE', { book_id: bookId, page_id: currentPageId });
      if (response.next_page_id) {
        await fetchPage(response.next_page_id);
      } else {
        setError(t('error.lastPage'));
        setTimeout(() => setError(''), 1500);
      }
    } catch (error) {
      setError(t('error.fetchNextPage'));
      logger.error('Error fetching next page:', error);
    }

    // Set a debounce timer to prevent rapid re-clicks
    pageNavigationDebounceRef.current = setTimeout(() => {
      pageNavigationDebounceRef.current = null;
    }, 1000);
  };

  // Add cleanup effect for the navigation debounce timer
  useEffect(() => {
    return () => {
      if (pageNavigationDebounceRef.current) {
        clearTimeout(pageNavigationDebounceRef.current);
      }
    };
  }, []);

  const handleBookSelect = useCallback((selectedBookId, selectedNativeLanguage, selectedLearningLanguage) => {
    // Abort any ongoing fetchPage request
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    
    // Batch all state updates together
    ReactDOM.unstable_batchedUpdates(() => {
      if (selectedNativeLanguage !== nativeLanguage) {
        setNativeLanguage(selectedNativeLanguage);
        onLanguageChange(selectedNativeLanguage);
      }
      if (selectedLearningLanguage !== learningLanguage) {
        setLearningLanguage(selectedLearningLanguage);
      }
      // Set bookId last to trigger the loading effect
      setBookId(selectedBookId);
    });
  }, [nativeLanguage, learningLanguage, onLanguageChange]);

  const handleLanguageSelect = (newNativeLanguage, newLearningLanguage, nativeLanguageDirection, learningLanguageDirection) => {
    if (newLearningLanguage && (!userProfile || userProfile.subscription_tier !== 'premium')) {
      setIsProductPromotionModalOpen(true);
      return;
    }
    if (newNativeLanguage !== nativeLanguage || newLearningLanguage !== learningLanguage || nativeLanguageDirection !== nativeLanguageDirection || learningLanguageDirection !== learningLanguageDirection) {
      // Abort any ongoing fetchPage request
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      
      logger.debug(`Native language: ${newNativeLanguage}, Learning language: ${newLearningLanguage}`);
      setLearningLanguage(newLearningLanguage);
      setLearningLanguageDirection(learningLanguageDirection);
      setNativeLanguage(newNativeLanguage);
      setNativeLanguageDirection(nativeLanguageDirection);
    
      i18n.changeLanguage(newNativeLanguage);
      
      onLanguageChange(newNativeLanguage);

      // Reset all book-related states
      setBookId('');
      setBookDetails(null);
      setCurrentPageId(null);
      setCurrentPage('');
      setNativeContent(null);
      setLearningContent(null);
      setAssociations(null);
      setPagePronunciation(null);
      setPageFurigana(null);
      setPageAssociations(null);
      setPageNumberInput('');
      setIsPageNumberChanged(false);
      setLoadingProgress(0);
      setLoadingLabel(t('welcome.chooseBook'));
      
      // Update URL to remove book and page parameters
      const params = new URLSearchParams();
      params.set('n', newNativeLanguage);
      params.set('l', newLearningLanguage);
      navigate(`?${params.toString()}`, { replace: true });
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleTextAlignmentChange = (event) => {
    const newAlignment = event.target.value;
    setTextAlignment(newAlignment);
    localStorage.setItem('textAlignment', newAlignment);
  };
  
  const handleDyslexicFontToggle = () => {
    const newValue = !dyslexicFontEnabled;
    setDyslexicFontEnabled(newValue);
    localStorage.setItem('dyslexicFontEnabled', newValue);
  };

  const toggleNativeLanguageVisibility = () => {
    setShowNativeLanguage(!showNativeLanguage);
  };

  const togglePronunciationVisibility = () => {
    setShowPronunciation(prev => !prev);
  };
  
  // const closeWordDetailsModal = () => {
  //   setIsWordDetailsModalOpen(false);
  //   setSelectedWord(null);
  // };

  useEffect(() => {
    const savedTheme = localStorage.getItem('selectedTheme');
    logger.debug('Loaded saved theme:', savedTheme);
    if (savedTheme) {
      setCurrentTheme(savedTheme);
      setShowThemePicker(false);
      document.documentElement.setAttribute('data-theme', savedTheme.toLowerCase());
    } else {
      setShowThemePicker(true);
    }
    setThemeLoaded(true);
  }, []);

  const handleWordHover = (word) => {
    setHoveredWord(word);
  };

  // const handleWordClick = (word, language) => {
  //   setSelectedWordDetails({
  //     clickedWord: word,
  //     clickedWords: [word],
  //     otherLanguageWords: [],
  //     clickedWordLanguageId: language,
  //     otherLanguageId: language === learningLanguage ? nativeLanguage : learningLanguage,
  //     learningLanguage: learningLanguage,
  //     nativeLanguage: nativeLanguage
  //   });
  //   setIsWordDetailsModalOpen(true);
  // };

  const renderBookTitle = () => {
    logger.info('Book details:', bookDetails);
    if (!bookDetails || !bookDetails.title || !bookDetails.title_words) return null;
  
    const title = bookDetails.title;
    const titleWords = bookDetails.title_words;
    const isSpaceSeparated = title.includes(' ');
  
    let currentIndex = 0;
    return (
      <div className="book-title" dir={learningLanguageDirection}>
        {titleWords.map((word, index) => {
          const startIndex = title.indexOf(word, currentIndex);
          const endIndex = startIndex + word.length;
          const displayText = title.slice(startIndex, endIndex);
  
          currentIndex = endIndex;
  
          return (
            <React.Fragment key={index}>
              <span className="title-word">
                {displayText}
              </span>
              {isSpaceSeparated && index < titleWords.length - 1 && (
                <span className="title-space"> </span>
              )}
            </React.Fragment>
          );
        })}
      </div>
    );
  };
  
  useEffect(() => {
    if (currentPage) {
      setPageNumberInput(currentPage.toString());
      setIsPageNumberChanged(false);
    }
  }, [currentPage]);

  const handlePageNumberChange = (e) => {
    const value = e.target.value;
    setPageNumberInput(value);
    setIsPageNumberChanged(value !== currentPage.toString());
  };

  const handlePageNumberBlur = () => {
    if (!pageNumberInput) {
      setPageNumberInput(currentPage.toString());
      setIsPageNumberChanged(false);
    }
  };

  const handleJumpToPage = async () => {
    if (!bookId || !pageNumberInput) return;

    try {
      const response = await apiRequest('GET', 'BOOK_PAGE_BY_NUMBER', 
        { book_id: bookId },
        { page_number: pageNumberInput }
      );
      if (response && response.page_id) {
        await fetchPage(response.page_id);
        setIsPageNumberChanged(false);
      } else {
        setError(t('error.invalidPageNumber'));
        setTimeout(() => setError(''), 1500);
      }
    } catch (error) {
      setError(t('error.fetchPageByNumber'));
      logger.error('002 - Error fetching page by number:', error);
    }
  };

  useEffect(() => {
    if (nativeLanguageDirection === 'rtl') {
      document.documentElement.style.setProperty('--menu-toggle-right', 'auto');
      document.documentElement.style.setProperty('--menu-toggle-left', '10px');
    } else {
      document.documentElement.style.setProperty('--menu-toggle-right', '10px');
      document.documentElement.style.setProperty('--menu-toggle-left', 'auto');
    }
  }, [nativeLanguageDirection]);

  // Update the loading progress effect
  useEffect(() => {
    let progress = 0;
    
    if (!nativeContent) {
      setLoadingLabel(bookId ? t('loading.page') : t('welcome.chooseBook'));
      progress = 0;
    } else if (isPremiumUser) {
      // Premium user loading states
      if (nativeContent && !learningContent) {
        setLoadingLabel(t('loading.learningContent'));
        progress = 25;
      } else if (!nativeContent && learningContent) {
        setLoadingLabel(t('loading.nativeContent'));
        progress = 25;
      } else if (nativeContent && learningContent && !associations) {
        setLoadingLabel(t('loading.associations'));
        progress = 50;
      } else if (associations && !pagePronunciation) {
        setLoadingLabel(t('loading.phonetics'));
        progress = 75;
      } else if (learningLanguage === 'ja' && pagePronunciation && !pageFurigana) {
        setLoadingLabel(t('loading.furigana'));
        progress = 90;
      } else if (
        (learningLanguage === 'ja' && pageFurigana) || 
        (learningLanguage !== 'ja' && pagePronunciation)
      ) {
        setLoadingLabel('');
        progress = 100;
      }
    } else {
      // Non-premium user - only native content
      if (nativeContent) {
        setLoadingLabel('');
        progress = 100;
      }
    }

    setLoadingProgress(progress);
  }, [nativeContent, learningContent, associations, pagePronunciation, pageFurigana, learningLanguage, t, bookId, isPremiumUser]);

  // Fetch bookmarks when user profile changes
  useEffect(() => {
    if (userProfile?.subscription_tier === 'premium') {
      fetchBookmarks();
    }
  }, [userProfile]);

  const fetchBookmarks = async () => {
    try {
      const response = await apiRequest('GET', 'USER_BOOKMARKS');
      setBookmarks(response);
    } catch (error) {
      logger.error('Error fetching bookmarks:', error);
    }
  };

  const handleBookmarkToggle = async (bookId, pageId) => {
    if (!userProfile || userProfile.subscription_tier !== 'premium') {
      setIsProductPromotionModalOpen(true);
      return;
    }

    try {
      // Find any existing bookmark for this book
      const existingBookmarkForBook = bookmarks.find(b => b.book_id === bookId);
      
      // If there's an existing bookmark for this book but different page
      if (existingBookmarkForBook && existingBookmarkForBook.page_id !== pageId) {
        // Delete the old bookmark
        await apiRequest('DELETE', 'USER_BOOKMARK', { 
          book_id: bookId, 
          page_id: existingBookmarkForBook.page_id 
        });
        
        // Create the new bookmark
        await apiRequest('POST', 'USER_BOOKMARK', { 
          book_id: bookId, 
          page_id: pageId 
        }, null, { note: '' });
        
        // Update state
        setBookmarks(prev => [
          ...prev.filter(b => b.book_id !== bookId),
          { book_id: bookId, page_id: pageId }
        ]);
        setCurrentBookmark({ book_id: bookId, page_id: pageId });
        return;
      }
      
      // Handle toggle for exact same page (remove bookmark)
      if (existingBookmarkForBook && existingBookmarkForBook.page_id === pageId) {
        await apiRequest('DELETE', 'USER_BOOKMARK', { 
          book_id: bookId, 
          page_id: pageId 
        });
        setBookmarks(prev => prev.filter(b => b.book_id !== bookId));
        setCurrentBookmark(null);
        return;
      }
      
      // No existing bookmark for this book, create new one
      await apiRequest('POST', 'USER_BOOKMARK', { 
        book_id: bookId, 
        page_id: pageId 
      }, null, { note: '' });
      const newBookmark = { book_id: bookId, page_id: pageId };
      setBookmarks(prev => [...prev, newBookmark]);
      setCurrentBookmark(newBookmark);
      
    } catch (error) {
      logger.error('Error toggling bookmark:', error);
    }
  };

  // Update useEffect for currentPageId to check for bookmark
  useEffect(() => {
    if (bookId && currentPageId) {
      const bookmark = bookmarks.find(b => b.book_id === bookId);
      setCurrentBookmark(bookmark);
    }
  }, [bookId, currentPageId, bookmarks]);

  const handleTextSizeIncrease = () => {
    setTextSize(prevSize => {
      const newSize = Math.min(prevSize + 10, 200); // Max 200%
      localStorage.setItem('textSize', newSize);
      return newSize;
    });
  };

  const handleTextSizeDecrease = () => {
    setTextSize(prevSize => {
      const newSize = Math.max(prevSize - 10, 50); // Min 50%
      localStorage.setItem('textSize', newSize);
      return newSize;
    });
  };

  return (
    <div className={`parallel-text-container ${dyslexicFontEnabled ? 'dyslexic-font' : ''}`} dir={nativeLanguageDirection}>
        {showThemePicker && (
          <ThemePickerWelcomeScreen
          onThemeSelect={handleThemeSelect}
          onClose={closeThemePicker}
          currentTheme={currentTheme}
        />
        )}
        <button className="menu-toggle" onClick={toggleMenu}>☰</button>
        <Menu 
          onBookSelect={handleBookSelect} 
          onLanguageSelect={handleLanguageSelect} 
          open={menuOpen ? 'open' : ''} 
          currentTheme={currentTheme} 
          onThemeChange={handleThemeSelect}
          onLanguageOptionsChange={handleLanguageOptionsChange}
          textAlignment={textAlignment}
          onTextAlignmentChange={handleTextAlignmentChange}
          dyslexicFontEnabled={dyslexicFontEnabled}
          onDyslexicFontToggle={handleDyslexicFontToggle}
          onOpenCookiePreferences={openCookiePreferences}
          initialNativeLanguage={nativeLanguage}
          initialLearningLanguage={learningLanguage}
          userProfile={userProfile}
          isLoadingProfile={isLoadingProfile}
          onLogin={handleLogin}
          onSignup={handleSignup}
          onLogout={handleLogout}
          onPasswordReset={handlePasswordReset}
          nativeLanguageDirection={nativeLanguageDirection}
          shouldTriggerSubscribe={shouldTriggerSubscribe}
          onSubscribeTriggered={onSubscribeTriggered}
          getURLWithParams={getURLWithParams}
          onRefetchProfile={onRefetchProfile}
          bookmarks={bookmarks}
          onBookmarkClick={loadBookPage}
          selectedBookId={bookId}
        />
        <div className="toolbar-and-content" dir={nativeLanguageDirection}>
        <Toolbar
          onToggleNativeLanguage={toggleNativeLanguageVisibility}
          onTogglePronunciation={togglePronunciationVisibility}
          onToggleFurigana={toggleFurigana}
          bookDetails={bookDetails}
          currentPageId={currentPageId}
          nativeLanguage={nativeLanguage}
          currentPageNumber={currentPage}
          learningLanguage={learningLanguage}
          isContentLoaded={!!(nativeContent && learningContent)}
          isPronunciationLoaded={!!pagePronunciation}
          isFuriganaLoaded={!!pageFurigana}
          showFurigana={showFurigana}
          nativeLanguageDirection={nativeLanguageDirection}
          userProfile={userProfile}
          bookId={bookId}
          onBookmarkToggle={handleBookmarkToggle}
          isBookmarked={!!currentBookmark}
          onTextSizeIncrease={handleTextSizeIncrease}
          onTextSizeDecrease={handleTextSizeDecrease}
        />
          <div className={`book-content ${dyslexicFontEnabled ? 'dyslexic-font' : ''}`} dir={nativeLanguageDirection}>
            <div className="navigation-buttons">
              <button onClick={handlePreviousPage} disabled={!bookDetails || currentPage === 1 || isLoadingPage}>{t('navigation.previousPage')}</button>
              {renderBookTitle()}
              <button onClick={handleNextPage} disabled={!bookDetails || currentPage === bookDetails.page_count || isLoadingPage}>{t('navigation.nextPage')}</button>
            </div>
            
            {loadingProgress < 100 && loadingLabel && (
              <LoadingProgress 
                progress={loadingProgress} 
                label={
                  <div className="loading-label">
                    <span className="loading-text">{loadingLabel}</span>
                    <LoadingDots direction={nativeLanguageDirection} />
                  </div>
                } 
              />
            )}

            <ParallelTextHistory 
              dyslexicFontEnabled={dyslexicFontEnabled}
              textAlignment={textAlignment}
              learningContent={learningContent}
              nativeContent={nativeContent}
              associations={associations}
              pagePronunciation={pagePronunciation}
              pageFurigana={pageFurigana}
              learningLanguage={learningLanguage}
              nativeLanguage={nativeLanguage}
              showNativeLanguage={showNativeLanguage}
              showPhonetics={showPhonetics}
              showFurigana={showFurigana}
              nativeLanguageDirection={nativeLanguageDirection}
              learningLanguageDirection={learningLanguageDirection}
              userProfile={userProfile}
              textSize={textSize}
              menuOpen={menuOpen}
            />
            
            {bookDetails && (
              <div className="page-navigation" dir={nativeLanguageDirection}>
                <div className="book-page-of-total">
                  <input
                    type="text"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={pageNumberInput}
                    onChange={handlePageNumberChange}
                    onBlur={handlePageNumberBlur}
                    className="current-page-input"
                  />
                  {t('page.ofTotal')} {bookDetails.page_count}
                  <button 
                    className="skip-to-page-button"
                    onClick={handleJumpToPage} 
                    disabled={!isPageNumberChanged}
                  >
                    {t('page.skipTo')}
                  </button>
                </div>
              </div>
            )}
            
            {error && <p className="error-loading-page" style={{color: 'red'}}>{error}</p>}
          </div>
        </div>
      </div>
  );
};

export default ParallelTextViewer;
